import { config } from 'merchants/identance';
import { stageRoutes } from 'system/Routing/stageRoutes.lazy';
import { App } from '../App';

// eslint-disable-next-line
__webpack_public_path__ = `${(window as any).appConfig.cdn}/`;

(async () => {
  await App(config, stageRoutes);
})();
